<template>
  <nav aria-label="Pagination">
    <ul class="pagination pagination-sm">
      <li class="page-item" :class="{ disabled: mutablePage === 1 }">
        <button class="page-link" @click="onChangePage(1)" :disabled="mutablePage === 1">
          <font-awesome-icon :icon="['fas', 'angle-double-left']" />
        </button>
      </li>
      <li class="page-item" :class="{ disabled: mutablePage === 1 }">
        <button class="page-link" @click="onChangePage(--mutablePage)" :disabled="mutablePage <= 1">
          <font-awesome-icon icon="angle-left"/>
        </button>
      </li>
      <li class="page-item" v-if="mutablePage > 2">
        <button class="page-link" @click="onChangePage(mutablePage - 2)">
          {{ mutablePage - 2 }}
        </button>
      </li>
      <li class="page-item" v-if="mutablePage > 1">
        <button class="page-link" @click="onChangePage(mutablePage - 1)">
          {{ mutablePage - 1 }}
        </button>
      </li>
      <li class="page-item active">
        <button class="page-link">{{ mutablePage }}</button>
      </li>
      <li class="page-item" v-if="mutablePage < lastPage">
        <button class="page-link" @click="onChangePage(mutablePage + 1)">
          {{ mutablePage + 1 }}
        </button>
      </li>
      <li class="page-item" v-if="mutablePage < (lastPage - 1)">
        <button class="page-link" @click="onChangePage(mutablePage + 2)">
          {{ mutablePage + 2 }}
        </button>
      </li>
      <li class="page-item" :class="{ disabled: mutablePage === lastPage }">
        <button class="page-link" @click="onChangePage(mutablePage + 1)" :disabled="mutablePage === lastPage">
          <font-awesome-icon icon="angle-right"/>
        </button>
      </li>
      <li class="page-item" :class="{ disabled: mutablePage === lastPage }">
        <button class="page-link" @click="onChangePage(lastPage)" :disabled="mutablePage === lastPage">
          <font-awesome-icon icon="angle-double-right"/>
        </button>
      </li>
    </ul>
  </nav>
</template>

<script>
export default {
  name: "ComponentPagination",
  computed: {
    lastPage() {
      return Math.ceil(this.$props.total / this.$props.limit);
    }
  },
  props: {
    page: {
      type: [String, Number],
      required: true
    },
    limit: {
      type: [String, Number],
      required: false
    },
    total: {
      type: [Number],
      required: false
    }
  },
  watch: {
    page(page) {
      this.mutablePage = page;
    }
  },
  data() {
    return {
      mutablePage: parseInt(this.page)
    }
  },
  methods: {
    onChangePage(page) {
      if (page <= 0) {
        return;
      }

      this.mutablePage = parseInt(page);
      this.$emit('paginate', this.mutablePage);
    }
  }
}
</script>

<style lang="scss" scoped>
nav {
  display: inline-block;
}
</style>