<template>
  <layout-full-width :title="$tc('project', 2)">

    <div class="row">
      <div class="col-12">

        <div class="card">
          <div class="card-body">

            <div class="btn-toolbar mb-3" role="toolbar" aria-label="">
              <div class="btn-group btn-group-sm me-2" role="group" aria-label="">
                <component-pagination :page="page" :limit="limit" :total="total" @paginate="onChangePage"/>
              </div>
              <div class="input-group input-group-sm" style="height: 0;">
                <label for="projects-per-page" class="input-group-text">{{ $t('projects-per-page') }}</label>
                <select id="projects-per-page"
                        class="form-select form-select-sm"
                        v-model="limit"
                        @change="onChangeLimit">
                  <option :value="10">10</option>
                  <option :value="25">25</option>
                  <option :value="50">50</option>
                  <option :value="75">75</option>
                  <option :value="100">100</option>
                  <option :value="500">500</option>
                </select>
              </div>
            </div>

            <component-spinner v-if="loading" class="p-5"/>

            <div v-if="!loading" class="table-responsive">
              <table id="licenses-table" class="table table-sm">
                <thead>
                <tr>
                  <th class="text-center">ID</th>
                  <th class="text-center">{{ $t('projectStreet') }}</th>
                  <th class="text-center">{{ $t('projectPostcode') }}</th>
                  <th class="text-center">{{ $t('projectCity') }}</th>
                  <th class="text-center">{{ $t('projectNumber') }}</th>
                  <th class="text-center">{{ $t('projectPIN') }}</th>
                  <th class="text-center">{{ $t('insertDate') }}</th>
                  <th class="text-center">{{ $t('updateDate') }}</th>
                  <th class="text-center">{{ $t('firstName') }}</th>
                  <th class="text-center">{{ $t('lastName') }}</th>
                  <th class="text-center">{{ $tc('action', 2) }}</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="project in projects" :key="project.projectId">
                  <td class="text-center align-middle">{{ project.projectId }}</td>
                  <td class="text-center align-middle">{{ project.personStreet }}</td>
                  <td class="text-center align-middle">{{ project.personPostcode }}</td>
                  <td class="text-center align-middle">{{ project.personCity }}</td>
                  <td class="text-center align-middle">{{ project.projectNumber }}</td>
                  <td class="text-center align-middle">{{ project.projectPin }}</td>
                  <td class="text-center align-middle">{{ moment(project.projectMetaInsertDt).format('DD.MM.YYYY') }}</td>
                  <td class="text-center align-middle">
                    <template v-if="project.projectMetaUpdateDt">{{ moment(project.projectMetaUpdateDt).format('DD.MM.YYYY') }}</template>
                  </td>
                  <td class="text-center align-middle">{{ project.personFirstName }}</td>
                  <td class="text-center align-middle">{{ project.personLastName }}</td>
                  <td class="text-center align-middle">
                    <router-link class="btn btn-sm btn-outline-primary"
                                 :to="{ name: 'edit-project', params: { projectNumber: project.projectNumber, projectPin: project.projectPin }}">
                      <font-awesome-icon icon="pencil-alt"/>
                    </router-link>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </layout-full-width>
</template>

<script>
import moment from "moment";
import LayoutFullWidth from "@/layouts/LayoutFullWidth";
import ComponentSpinner from "@/components/ui/ComponentSpinner";
import ComponentPagination from "@/components/ui/ComponentPagination";

export default {
  name: "PageProjects",
  components: { LayoutFullWidth, ComponentSpinner, ComponentPagination },
  data() {
    return {
      loading: true,
      page: 1,
      limit: 25,
      moment
    }
  },
  computed: {
    projects: function () {
      return this.$store.state.ifs.projects
    },
    total() {
      return this.$store.state.ifs.projectsCount
    }
  },
  methods: {
    async onChangePage(page) {
      if (page < 0) {
        return;
      }

      this.page = parseInt(page);
      await this.onRefresh();
    },
    async onChangeLimit() {
      this.page = 1;
      await this.onRefresh();
    },
    async onRefresh() {
      this.loading = true;
      await this.$store.dispatch('ifs/listProjects', {
        paginationPage: this.page - 1,
        paginationPageSize: this.limit
      });
      this.loading = false;
    }
  },
  async beforeMount() {
    await this.onRefresh();
  }

}
</script>

<style scoped lang="scss"></style>